@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./../node_modules/font-awesome/css/font-awesome.css";
// @import "~ngx-smart-modal/styles/ngx-smart-modal";

$primary: var(--primary-color);

body {
  font-family: "Plus Jakarta Sans", serif;
  overflow-x: hidden;
  -webkit-overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  color: #212121;
  letter-spacing: 0.03vw;
  scroll-behavior: smooth;
}
html {
  box-sizing: border-box;
  height: 100%;
  max-width: 100% !important;
  /* overflow-x: hidden; */
  position: relative;
  width: 100%;
  line-height: 2.4vw;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
.modal.fade-anim.in {
  padding-top: 20px;
  z-index: 1000;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
  clear: both;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
mark {
  background: #ff0;
  color: #212121;
}
small {
  font-size: 80%;
}
img {
  border: 0;
}
.pt-70 {
  padding-top: 2.5vw;
}
.pt-80 {
  padding-top: 5.5vw;
}
.pt-120 {
  padding-top: 7.4vw;
}
.pt-250 {
  padding-top: 13vw !important;
}
.pb-80 {
  padding-bottom: 5vw;
}
.pb-120 {
  padding-bottom: 7.4vw;
}
.pb-200 {
  padding-bottom: 9vw;
}
.pb-300 {
  padding-bottom: 15vw;
}
.mar-0 {
  margin: 0;
}
.text-center {
  text-align: center;
  margin: 0 auto;
}
.text-light {
  color: #888888;
  // color: #212121;
}
.testimonial-text {
  color: #888888;
}
.text-dark {
  color: #888888;
  // color: #212121;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 3vw;
}
hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 0.07vw solid #c0c0c0;
  margin: 0 0.3vw;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #212121;
  font-weight: 500;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: none;
}
a:focus {
  outline: 0.36vw auto -webkit-focus-ring-color;
  outline-offset: -0.15vw;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
.img-responsive,
.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 0.3vw;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.3vw;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  border: 0;
  border-top: 1px solid #eee;
}

[role="button"] {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 400;
  line-height: inherit;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: inherit;
  color: #777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}
h1.large,
.h1.large {
  /* 36px */
  font-size: 2.39vw;
  font-weight: 600;
  line-height: normal;
}
h1,
.h1 {
  /* 28px */
  font-size: 2vw;
  font-weight: 600;
}
h2,
.h2 {
  /* 1.5vw */
  font-size: 1.3vw;
  line-height: 1.7vw;
  font-weight: 600;
}
h3,
.h3 {
  /* 18px */
  font-size: 1.2vw;
  line-height: 1.4vw;
  font-weight: 400;
}
h4,
.h4 {
  /* 1.17vw */
  line-height: 1.8vw;
  font-size: 1.17vw;
  font-weight: 400;
}
h5,
.h5 {
  /* 14px */
  font-size: 0.85vw;
  line-height: 1.6vw;
  font-weight: 400;
}
h6,
.h6 {
  /* 13px */
  font-size: 0.9vw;
  font-weight: 400;
}
p {
  margin: 0 0 1.5vw;
}
.main-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 85%;
}
.mini-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 75%;
}
.main-row {
  margin-left: -1vw;
  margin-right: -1vw;
}
.width-1,
.width-2,
.width-3,
.width-4,
.width-5,
.width-6,
.width-7,
.width-8,
.width-9,
.width-10,
.width-11,
.width-12 {
  float: left;
  position: relative;
  min-height: 0.07vw;
  padding-left: 1vw;
  padding-right: 1vw;
}
.width-12 {
  width: 100%;
}
.width-11 {
  width: 91.66666667%;
}
.width-10 {
  width: 83.33333333%;
}
.width-9 {
  width: 75%;
}
.width-8 {
  width: 66.66666667%;
}
.width-7 {
  width: 58.33333333%;
}
.width-6 {
  width: 50%;
}
.width-5 {
  width: 41.66666667%;
}
.width-4 {
  width: 33.33333333%;
}
.width-3 {
  width: 25%;
}
.width-2 {
  width: 16.66666667%;
}
.width-1 {
  width: 8.33333333%;
}
.main-title {
  width: 80%;
}

/* ==================================================
   #Navigation
  ================================================== */
.header__container {
  position: absolute;
  z-index: 99;
  width: 100%;
}
.header__logo__wave {
  display: block;
  top: 0;
  transform-origin: top;
  animation-name: animateWave;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0, 0.1, 1, 1);
  animation-direction: forwards;
}
.header__logo__wave-svg {
  position: absolute;
  top: 0;
  width: 20%;
}
.header__navigation {
  width: 100%;
  display: flex;
  align-items: center;
  height: 9.2vh;
}
.header__logo {
  z-index: 1;
  flex: 1;
  text-align: center;
}
.header__logo a,
.header__logo a:visited {
  color: #ffffff;
  text-decoration: none;
}
.header__logo a img {
  height: 6vh;
}
.header__item {
  flex: 4;
  display: flex;
  justify-content: center;
}
.header__social-icon {
  flex: 1;
  display: flex;
}
.header__social-icon img {
  margin: 0 0.7vw;
  width: 1.6vw;
  height: 1.5vw;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav ul li {
  float: left;
  position: relative;
}
nav ul li a h5,
nav ul li a h5:visited {
  display: block;
  font-size: 1vw;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  color: #ffffff;
  text-decoration: none;
}
nav ul li a h5:hover,
nav ul li a h5:visited:hover {
  color: #ffffff;
}
nav ul li a h5:not(:only-child):after,
nav ul li a h5:visited:not(:only-child):after {
  padding-left: 0.3vw;
  content: " ▾";
}
nav ul li ul li {
  min-width: 14vw;
}
nav ul li ul li a h5 {
  padding: 1vw;
  line-height: 1.5vw;
}
.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 0.22vw 0.8vw rgba(0, 0, 0, 0.15);
}
.sticky {
  position: fixed;
  top: 0;
  color: "#212121000";
}
.shape__change {
  width: 100%;
}
.font-drop {
  color: #2db1ff !important;
}
.font-drop:hover {
  color: #0081c9 !important;
}
.img {
  background-image: url(/assets/images/menu-wave.svg);
  background-repeat: no-repeat;
  height: 19vh;
}
.img-change {
  display: none;
  /* background-image: url("../assets/images/sticky-menu.svg"); */
  background-color: #ffffff;
  background-repeat: no-repeat;
  height: 9vh;
  width: 100%;
  position: fixed;
  animation-name: slide-fill;
  animation-duration: 1s;
  animation-direction: forwards;
  box-shadow: 0 0.22vw 0.8vw rgba(0, 0, 0, 0.15);
}

/* ==================================================
   #Banner
  ================================================== */

.main-banner {
  position: relative;
  min-width: 100%;
  height: 90%;
  overflow: hidden;
}
.main-banner__container {
  height: 90%;
  position: relative;
}
.main-banner__background-img:before {
  position: absolute;
  background: linear-gradient(
    90.44deg,
    rgba(0, 130, 203, 0.9) 0.31%,
    rgba(45, 177, 255, 0.9) 97.75%
  );
  content: "";
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  animation-name: overlay;
  // background: rebeccapurple;
  // animation-duration: 1.5s;
  // animation-iteration-count: 1;
  // animation-timing-function: cubic-bezier(0.1, 0.2, 0.8, 0.2);
}
.main-banner__background-img {
  background-image: url(/assets/images/banner-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  animation-name: overlay;
  // animation-duration: 1.5s;
  // animation-iteration-count: 1;
  // animation-timing-function: cubic-bezier(0.1, 0.2, 0.8, 0.2);
}
.main-banner__content {
  display: flex;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
  opacity: 0.99;
  color: #ffffff;
  height: 80%;
}
.main-banner__content-text {
  width: 50%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // padding: 0 3vw 0 1vw;
  padding: 9vh 0 0 0;
}
@media (max-width: 1280px) and (min-width: 1100px) {
  .banner__v2 {
    .main-banner__content-text {
      width: 56%;
      height: 56vh;
      .button-group {
        margin: 2vh 0 0;
      }
      h1.large {
        font-size: 2vw;
      }
      h1,
      h5 {
        margin: 0;
      }
    }
  }
}

.main-banner__content-title {
  line-height: 1.2em;
}
.main-banner__content-illustration img {
  width: 33vw;
}
.main-banner__content-animation {
  animation: opacity 2s 1;
}
h5.main-banner__content-description {
  font-weight: 400;
}
.main-banner__content-illustration {
  text-align: center;
  padding: 4.12vh 0 0 0;
  width: 50%;
}
/* ****** #Wave ****** */
.app__wave-container {
  position: absolute;
  bottom: -1px;
  z-index: 9;
  overflow: hidden;
  width: 100%;
}
.app__wave-container img {
  display: block;
  transform-origin: bottom;
  animation-name: animateWave;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0, 0.1, 1, 1);
  animation-direction: forwards;
  width: 100%;
}
.initial .app__wave-container img {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* ==================================================
   #Button
  ================================================== */
.button-group.banner-btn {
  margin: 1.99vh 0 0 0;
  display: flex;
}
.button-group {
  margin: 1.99vh 0 0 0;
}
.button-group a {
  margin: 0.99vh 0.52vw;
}
.btn {
  font-size: 1vw;
  font-weight: 500;
  color: #fff;
  line-height: 1;
  text-align: center;
  padding: 1.3vh 3vw;
  border: 0 none;
  border-radius: 7.3vw;
  outline: 0 none;
  position: relative;
  z-index: 1;
}
.button-group a {
  cursor: pointer;
}
.btn.btn-bordered {
  background: transparent none repeat scroll 0 0;
  color: #fff;
  border: 0.1vh solid #fff;
}
.project .btn.btn-bordered {
  background: var(--primary-color);
}
.btn.btn-bordered.active {
  background: linear-gradient(-45deg, #2db1ff 0%, #0082cb 100%);
  color: #fff;
  border: 0.1vh solid #2db1ff;
  width: 100%;
  padding: 1vh 3vw;
}
.btn.btn-bordered.active {
  background: linear-gradient(-45deg, #2db1ff, #0082cb);
  color: #fff;
  border: 0.1vh solid #2db1ff;
  width: 100%;
  padding: 1vh 3vw;
}
.btn.btn-bordered.active:hover {
  background: #0000 none repeat scroll 0 0;
  color: #2db1ff;
  border: 0.1vh solid #2db1ff;
}
.download button.btn-bordered.active {
  background: var(--primary-color);
  color: #fff;
  border: 0.1vh solid var(--primary-color);
  width: auto;
  padding: 1vh 3vw;
}
.download button.btn-bordered.active:hover {
  background: #ffffff;
  color: var(--primary-color);
  border: 0.1vh solid var(--primary-color);
}
.btn.btn-bordered.active:hover {
  background: transparent none repeat scroll 0 0;
  color: #2db1ff;
  border: 0.1vh solid #2db1ff;
}
#scroller {
  overflow: auto;
  max-height: 55vh;
  -webkit-overflow-scrolling: touch;
}
/* .button-group a:last-child {
  margin-right: 0;
} */
.btn,
.btn:active,
.btn:hover,
.btn.btn-bordered::before {
  background: linear-gradient(-45deg, #2db1ff 0%, #0082cb 100%);
}
.btn:active,
.btn:hover {
  border: none;
  padding: 1.3vh 3vw;
  box-shadow: 0 0.7vw 1.83vw rgba(0, 0, 0, 0.3);
}
.btn.btn-bordered:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 7.3vw;
  background: #fff0 none repeat scroll 0 0;
  transition: all 0.3s ease 0s;
  z-index: -1;
}
a.hover-underline-animation {
  font-size: 0.888vw;
  font-weight: 500;
  cursor: pointer;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
}
.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.3vh;
  bottom: 0.3vw;
  left: 0;
  background-color: #2db1ff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.hover-underline-animation:hover::after,
a.active.hover-underline-animation::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
a.btn.btn-bordered.project-btn:hover {
  // background: linear-gradient(-45deg, #23527c 0%, #385a9a 100%);
  border: none;
}
/* ==================================================
   #Services
  ================================================== */
.main-title h5 {
  padding: 0 8vw;
}
div#services {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 2vw 0 0 0;
}
.services__service-box {
  padding: 1.5vw 0.6vw 1.1vw 0.6vw;
  margin: 0.5vw 0.7vw 1vw 0.7vw;
  transition: all 0.3s ease 0s;
  background: #fff;
  flex: 1 0 31%;
}
.services__service-box:hover {
  box-shadow: 0.22vw 0.5vw 1.83vw 0 rgba(0, 0, 0, 0.05);
}
.services__service-box a:hover {
  color: #2db1ff;
}
.services__icon.primary img {
  width: 45%;
}
.services__text h5 {
  padding: 0 1vw;
}
.services__icon.primary {
  background: #2db1ff;
  box-shadow: 0 2vw 2vw 0 rgb(32 99 238 / 10%);
}
.services__icon {
  border-radius: 100%;
  width: 6.3vw;
  height: 6.3vw;
  line-height: 6.3vw;
  margin: 0 auto 1.5vw;
  position: relative;
}
.services__icon.primary:after {
  border-color: #2db1ff;
}
.services__service-box:hover .services__icon.primary:after {
  opacity: 0;
  transform: scale(0.85);
}
.services__service-box:hover .services__icon.primary {
  background: #0082cb;
}
.services__icon:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 7.2vw;
  height: 7.2vw;
  border-radius: 100%;
  border: 0.07vw solid;
  transition: all 0.3s ease 0s;
  z-index: 0;
}
img.services__shape {
  position: absolute;
  top: 1.5vw;
  height: 6.5vw;
}
.services__service-box:hover .services__shape {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

/* ==================================================
   #Whom do we serve
  ================================================== */

.bg-light {
  background-color: #f9f9f9;
  position: relative;
  z-index: 5;
}
// .bg-light::before {
//   position: absolute;
//   top: -9.25vw;
//   left: 0;
//   bottom: 0;
//   content: " ";
//   // background: url(/assets/images/shape-top-light.svg);
//   background-size: cover;
//   background-position: top;
//   background-repeat: no-repeat;
//   width: 100%;
//   z-index: -9;
//   height: 10.5vw;
// }
.testimonial .bg-light::before {
  background: #f9f9f9;
}
// .bg-light::after {
//   position: absolute;
//   bottom: -9.6vw;
//   left: 0;
//   content: " ";
//   // background: url(/assets/images/shape-bottom-light.svg);
//   background-size: cover;
//   background-position: bottom;
//   background-repeat: no-repeat;
//   width: 100%;
//   height: 10.6vw;
//   z-index: -9;
// }
section.serve.bg-light {
  display: -webkit-box;
}
div#serve {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 2.5vw 2.9vw 2.5vw 2.5vw;
}
.serve__serve-box {
  padding: 2vw;
  margin: 0vw 1.5vw;
  border-radius: 0.7vw;
  transition: all 0.3s ease 0s;
  background: #fff;
  flex: 1 0 28%;
  box-shadow: 0.3vw 0.3vw 0.8vw 0.36vw rgb(161 158 158 / 0.25);
}
.serve__icon img {
  height: 4.5vw;
}
.serve__blue {
  text-align: center;
  background: #2db1ff;
  width: 100%;
  padding: 0.3vw;
  border-radius: 0 0 0.36vw 0.36vw;
  position: absolute;
  left: 0;
  bottom: -0.3vw;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.serve__serve-box:hover .serve__blue {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.serve__serve-box {
  position: relative;
}

/* ==================================================
   #What we have done
  ================================================== */
.tab {
  color: #212121;
  border-radius: 0.22vw 0.22vw 0 0;
}
div#tab {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 1vw 0;
}
.tab__tab-box {
  padding: 2vw 1vw 0vw 1vw;
  border-radius: 0.7vw;
  transition: all 0.3s ease 0s;
  background: #fff;
  flex: 1 0 33%;
  max-width: 50%;
}
.tabs {
  background: transparent;
  padding: 0;
  border-radius: 1.5vw;
  transition: color 0.15s ease-in;
  position: relative;
  text-align: center;
}
.panels {
  background: #fff;
  width: 100%;
  overflow: hidden;
  padding: 0.7vw 1.5vw 0 1.5vw;
}
.panel {
  display: flex;
  flex-flow: wrap;
  animation: fadein 0.2s;
}
.tab__text {
  padding: 0.2vw 0.7vw 0 0.3vw;
}
.panel-title {
  font-size: 1.5em;
  font-weight: bold;
}
.tab__img img {
  width: 100%;
}
.radio {
  display: none;
}

.tabs div {
  text-decoration: none;
  color: #212121;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition-duration: 0.6s;
}
.tabs .tab:not(.active) {
  border-radius: 4.5vw;
  background: #f9f9f9;
}
.tabs .tab {
  margin: 5px;
  cursor: pointer;
}
.tabs .tab:hover {
  color: #fff;
  transition: all 0.2s linear;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-image: linear-gradient(to right, #0082cb 0%, #2db1ff 100%);
}
.tabs div.active {
  color: #fff;
  transition: all 0.2s linear;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-image: linear-gradient(to right, #0082cb 0%, #2db1ff 100%);
  border-radius: 4.5vw;
}
// .tabs .selector {
//   height: 100%;
//   display: inline-block;
//   position: absolute;
//   left: 0px;
//   top: 0px;
//   z-index: 1;
//   border-radius: 1.5vw;
//   transition-duration: 0.6s;
//   transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   background-image: linear-gradient(to right, #0082cb 0%, #2db1ff 100%);
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0082cb', endColorstr='#2db1ff',GradientType=1 );
// }
.technology .tabs .selector {
  width: 50% !important;
  background: $primary;
  background-image: none;
}

.technology .noTabs .ssButton {
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 40px;
  z-index: 1;
  background: $primary;
}

/* ==================================================
   #get a quote
  ================================================== */

.quote {
  position: relative;
  min-width: 100%;
  height: auto;
  overflow: hidden;
}
.quote__container {
  height: 100%;
  position: relative;
}
.quote__background-img:before {
  position: absolute;
  background: linear-gradient(
    90.44deg,
    rgba(0, 130, 203, 0.9) 0.31%,
    rgba(45, 177, 255, 0.9) 97.75%
  );
  content: "";
  min-width: 100%;
  height: 100%;
  overflow: hidden;
}
.quote__background-img {
  background-image: url(/assets/images/banner-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-width: 100%;
  height: auto;
  overflow: hidden;
}
.quote__content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  opacity: 1;
  padding: 3vw 1.5vw 11.5vw 1.5vw;
  color: #ffffff;
}
.quote__content img {
  width: 100%;
}
.quote__content-title {
  line-height: 1.2em;
}
.quote__content-description {
  margin: 1vw 0 2.2vw 0;
}
// for testimonial
@media (max-width: 780px) {
  app-read-more > .testimonial-text {
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/* ==================================================
   #What client say about us
  ================================================== */

section.testimonial {
  position: relative;
  margin-top: -12%;
  padding-top: 12px;
}
#testimonial .position-rel {
  position: relative;
}
.testimonial__block {
  background-color: #fff;
  z-index: 1;
  min-height: 24vw;
  box-shadow: 0 0.36vw 1.5vw 0 rgb(0 0 0 / 0.12);
  padding: 2vw 2vw 2vw 0;
  margin: 0 1.8vw;
  // margin: 1.8vw;
  float: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div#testimonial {
  margin: 1.5vw 0 0 0;
}
.testimonial__description h4 {
  text-align: left;
  padding: 0 0.7vw;
  line-height: 1.6;
  font-weight: 300;
}
.testimonial__description {
  line-height: 1.8;
  position: relative;
  padding: 4vw 0 0.7vw 0;

  padding-left: 2vw;
}
.testimonial__designation {
  padding: 0 10px;
}
.testimonial__description:before {
  content: url(/assets/images/client-back.svg);
  position: absolute;
  padding: 0 0 0 1vw;
  // top: -1%;
  top: 12%;
  left: 0;
  z-index: 11;
}
.testimonial__data {
  // display: flex;
  min-height: 7vh;
}
.testimonial__designation h5 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.testimonial__pic {
  margin: 0 1vw 0 0;
  position: absolute;
  top: -65px;
  display: flex;
}
.testimonial__pic img {
  // border-radius: 50%;
  display: block;
}
.testimonial__name {
  // display: flex;
  align-items: center;
  // inline-size: 14vw;
  margin-top: 4vw;
  padding-left: 10px;
}
.testimonial__name h5 {
  font-weight: 500;
}
.testimonial__name i {
  margin-left: 1px;
  color: #ffffff;
  background: #2db1fe;
  padding: 3px 4px;
  border-radius: 50%;
}
.testimonial__title {
  font-size: 1.2vw;
  text-transform: capitalize;
  font-weight: bold;
  margin: 0;
}
app-read-more > .text-light {
  // height: 240px;
  height: 213px;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-read-more > .testimonial-text {
  max-height: 156px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.testimonial_height {
  height: auto !important;
  max-height: none !important;
}
// .owl-wrapper-outer {
//   padding: 1.5vw 0 !important;
// }
.owl-stage-outer {
  padding: 1.5vw 0 !important;
}
#testimonial .owl-stage-outer {
  padding: 4.7vw 0 !important;
}
.testimonial__description i {
  display: none;
}
/* ==================================================
   #Blog
  ================================================== */
.inline.left {
  display: inline;
  float: left;
}
.inline.right {
  display: inline;
  float: right;
}
.blog__text h3 {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: clip !important;
  display: -webkit-box !important;
  line-clamp: 2 !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
/* .blog__text a.hover-underline-animation {
  bottom: 0.7vw;
  position: absolute;
} */
.blog__text h3 {
  clear: both;
  font-weight: 500;
  line-height: normal;
}
.blog__text h3:hover {
  color: #2db1fe;
}
.blog__box {
  background-color: #fff;
  box-shadow: 0 0.36vw 1.5vw 0 rgb(0 0 0 / 0.12);
  padding: 0 0 0.7vw 0;
  margin: 0 1.5vw;
  /* min-height: 33vw; */
  position: relative;
  float: left;
}
.blog__text {
  margin: 0.5vw 1.8vw;
}
.blog__img img {
  width: 100%;
}
/* owl nav */
.arrows {
  font-weight: 600 !important;
  border: none;
  outline: none;
  font-size: 28px !important;
  color: #2db1fe;
  background: #fff;
  height: 32px;
  width: 32px;
  box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 10%);
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.arrows:hover {
  color: #fff;
  background: #2db1fe;
}
.arrows_portfolio {
  font-weight: 600 !important;
  border: none;
  outline: none;
  font-size: 28px !important;
  height: 32px;
  width: 32px;
  box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 10%);
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
::ng-deep .owl-theme .owl-controls .owl-buttons .owl-prev:hover,
.owl-theme .owl-controls .owl-buttons .owl-next:hover {
  color: #2db1ff !important;
}
::ng-deep .owl-theme .owl-controls .owl-buttons .owl-prev:hover .fa-stack,
.owl-theme .owl-controls .owl-buttons .owl-next:hover .fa-stack {
  background: #ffffff;
  border-radius: 50%;
}
::ng-deep .owl-theme .owl-controls .owl-buttons div {
  background: #2db1ff !important;
  box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 0.1);
  color: #fff !important;
  margin: 0.7vw !important;
  padding: 0 !important;
  font-size: 1.24vw !important;
  outline: none;
  opacity: 1 !important;
}

div#root {
  margin: 0 auto;
  display: table;
  z-index: 99;
}

/* ==================================================
   #Let's connect!
  ================================================== */
div#connect {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 0 3vw 0 3vw;
}
.connect__part {
  padding: 0 1.5vw 2.2vw 1.5vw;
  margin: 0.7vw 1.5vw;
  border-radius: 0.7vw;
  transition: all 0.3s ease 0s;
  flex: 1 0 28%;
}
div#connect .main-title h5 {
  margin: 0 auto;
  width: 90%;
  padding: 0;
  color: #888888;
  // color: #212121;
}
.connect__part:last-child {
  padding: 3.7vw 1.5vw 2vw 1.5vw;
}
.contact__bg-hover {
  box-shadow: 0 0.22vw 1.5vw 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 0.7vw;
  padding: 2.2vw 1.4vw;
  margin: 1.5vw 3.67vw 2vw 3.67vw;
  transition: all 0.3s ease 0s;
  position: relative;
  overflow: hidden;
}
.contact__bg-hover::after {
  position: absolute;
  content: "";
  height: 0.7vw;
  background-color: #0082cb;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: background-color 0.3s ease 0s;
}
.contact__bg-hover:nth-child(2)::after {
  background-color: #2db1ff;
}
li.contact__bg-hover h5,
li.contact__bg-hover h2 {
  padding: 0;
  margin: 0;
}
div#connect__inner ul .contact__bg-hover span img {
  margin: 1vw auto 0 auto;
  display: block;
}
li.contact__bg-hover span img {
  padding: 0 0 0.8vw 0;
}
ul.social {
  margin: 0;
  width: 100%;
  text-align: center;
}
ul.social li {
  display: inline-block;
}
ul.social li a {
  display: inline-block;
  font-size: 1.2vw;
  line-height: 2.6vw;
  width: 2.5vw;
  height: 2.5vw;
  text-align: center;
  border-radius: 50%;
  background-color: #0082cb;
  color: #fff;
  margin: 0 0.7vw 0 0;
}
ul.social > li > a:hover {
  text-decoration: none;
  background-color: #2db1ff;
}
ul.social li a {
  transition: all 200ms ease-in;
  transform: scale(1);
}
ul.social li a:hover {
  box-shadow: 0 0 1.2vw #212121;
  // box-shadow: 0 0 1.2vw #2121210003b;
  z-index: 2;
  transition: all 200ms ease-in;
  transform: scale(1.1);
}
.buttons a i {
  background: #fff;
  color: #2db1fe;
  border-radius: 50%;
  padding: 8px 10px 0px 10px;
}
/* Form */

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #888888;
  // color: #212121;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
}
.form-control:focus {
  color: #212121;
  background-color: #fff;
  border: none;
  outline: 0;
}
.form-group input,
.form-group textarea {
  font-size: 1vw;
  height: 3.4vw;
  padding: 1.8vh 1vw;
  border: none;
  box-shadow: none;
  border-radius: 0;
  box-shadow: 0 0.36vw 0.7vw rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
.textarea_form_group textarea {
  height: auto !important;
  margin-bottom: 1.2vh !important;
}
.textarea_form_group {
  text-align: left;
  margin-bottom: 0.2vh;
}
input.form-control.ng-untouched.ng-pristine.ng-invalid
  .contact__box
  .form-group
  textarea {
  height: 11vw;
  padding: 1vw;
}
button.btn.btn-bordered.active span {
  padding: 0 1vw 0 0;
}

/* ==================================================
   #Footer
  ================================================== */
section.footer {
  position: relative;
  bottom: 0%;
  padding: 4.5vw 0;
  z-index: 9;
  width: 100%;
  display: block;
  clear: both;
  background-color: #fff;
  .main-container {
    display: flex;
    flex-wrap: wrap;
  }
}
.footer ul.menu li {
  display: block;
  list-style: none;
  line-height: 2.1vw;
  text-align: left;
}
.footer ul.menu li a {
  font-size: 0.9vw;
  font-weight: 400;
  color: #888888;
}
.footer ul.menu li a:hover {
  color: #2db1ff;
}
section.footer h5 {
  color: #888888;
}
.footer__bottom h5 {
  color: #212121 !important;
}
ul.footer__social {
  margin: 0;
  width: 100%;
  text-align: left;
}
ul.footer__social li {
  display: inline-block;
}
ul.footer__social li a {
  display: inline-block;
  font-size: 1.5vw;
  text-align: left;
  color: #212121;
  margin: 0 1vw 0 0;
}
ul.footer__social li a:hover {
  text-decoration: none;
  color: #2db1ff;
}

/* Footer Bottom */
.footer__bottom {
  background-color: #eceff1;
  float: left;
  width: 100%;
  // margin: 1.4vw 0 0 0;
  padding: 1vw 0;
}
.footer__bottom__copyright h5 {
  font-weight: 500;
  font-size: 1vw;
  line-height: 27px;
}
.footer__bottom__copyright h6 {
  color: #888888;
}
/* Return to Top */
#return-to-top {
  position: fixed;
  bottom: 6vw;
  right: 1.5vw;
  z-index: 9999;
  background: #2db1ff;
  width: 3.7vw;
  height: 3.7vw;
  display: block;
  box-shadow: 0 0.36vw 1.5vw 0 rgb(0 0 0 / 0.22);
  text-decoration: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: none;
  transition: all 0.3s ease;
}
#return-to-top i {
  color: #fff;
  z-index: 999;
  margin: 0;
  position: relative;
  left: 1.17vw;
  top: 0.8vw;
  font-size: 1.4vw;
  font-weight: 400;
  transition: all 0.3s ease;
}
#return-to-top:hover {
  background: #0082cb;
}
#return-to-top:hover i {
  color: #fff;
  top: 0.36vw;
}

/* chat */
#chat {
  position: fixed;
  bottom: 1.5vw;
  right: 1.5vw;
  z-index: 99;
  background: #0082cb;
  width: 3.7vw;
  height: 3.7vw;
  display: block;
  box-shadow: 0 0.36vw 1.5vw 0 rgb(0 0 0 / 0.22);
  text-decoration: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 0.3s ease;
}
#chat i {
  color: #fff;
  z-index: 999;
  margin: 0;
  position: relative;
  left: 1.17vw;
  top: 0.8vw;
  font-size: 1.4vw;
  font-weight: 400;
  transition: all 0.3s ease;
}
#chat:hover {
  background: #2db1ff;
}
#chat:hover i {
  color: #fff;
  top: 0.36vw;
}

/* Share */
#share {
  position: fixed;
  bottom: 1.8vw;
  right: 1.5vw;
  z-index: 99;
  background: #0082cb;
  width: 3.7vw;
  height: 3.7vw;
  display: block;
  box-shadow: 0 0.36vw 1.5vw 0 rgb(0 0 0 / 0.22);
  text-decoration: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
}
#share i {
  color: #fff;
  z-index: 999;
  margin: 0;
  position: relative;
  left: 1.3vw;
  top: 0.8vw;
  font-size: 1.4vw;
  font-weight: 400;
  transition: all 0.3s ease;
}
#share i.fa-times {
  transform: rotate(180deg);
}
#share:hover {
  background: #2db1ff;
}
#share:hover i {
  color: #fff;
}

.sb-circles-dark .sb-wrapper .sb-icon,
.sb-circles-light .sb-wrapper .sb-icon {
  width: 3.8em !important;
  min-height: 3.8em !important;
}

// .sb-circles-dark .sb-wrapper .sb-icon:active{
//   outline: 0;
//   transition        : all 1.5s ease;
//   -webkit-transition: all 1.5s ease;
// }
// .sb-circles-dark .sb-wrapper .sb-icon:hover,.sb-circles-dark .sb-wrapper .sb-icon:focus{
//   color: #23527c;
//   text-decoration: none;
// }
// .sb-circles-dark .sb-wrapper .sb-icon:focus, .sb-circles-dark .sb-wrapper .sb-icon:focus{
//   outline: 0.36vw auto -webkit-focus-ring-color;
//   outline-offset: -2.15vw;
// }
// .sb-circles-dark .sb-wrapper .sb-icon:focus,
// .sb-circles-dark .sb-wrapper .sb-icon:hover,
// .sb-circles-light .sb-wrapper .sb-icon:focus,
// .sb-circles-light .sb-wrapper .sb-icon:hover{
//   width: calc(100% + 2px) !important;
//   height: calc(100% + 2px) !important;
//   margin-left: calc(-50% - 1px) !important;
//   background: #fff !important;
//   box-shadow: 0 0.36vw 1.5vw 0 rgb(0 0 0 / 28%) !important;
// }
.svg-inline--fa {
  width: 1.5em;
  height: 1.5em;
  vertical-align: text-bottom;
}

.social-buttons {
  position: fixed;
  bottom: 1.5vw;
  right: 6.5vw;
  z-index: 99;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;
  display: flex;
}
.social-button {
  margin: 0 0 0 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 3.7vw;
  height: 3.7vw;
  text-align: center;
  color: #fff;
  text-decoration: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.social-button::after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: 0.39s;
}
.social-button:focus::after,
.social-button:hover::after {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin-left: calc(-50% - 1px);
  background: #fff;
  box-shadow: 0 0.36vw 1.5vw 0 rgb(0 0 0 / 28%);
}
.social-button i,
.social-button svg {
  position: relative;
  z-index: 1;
  transition: 0.3s;
}
.social-button i {
  font-size: 1.4vw;
}
.social-button svg {
  height: 40%;
  width: 40%;
}
.social--buttons {
  transition: all 1.5s ease;
  -webkit-transition: all 1.5s ease;
  opacity: 0;
}
.active_button {
  opacity: 1;
}
.social--buttons::after {
  color: #3b5999;
}
.soc_btn_up_down {
  z-index: -5;
}
/* ==================================================
   #ABOUT PAGE
  ================================================== */

/* ==================================================
   #Inner Banner
  ================================================== */
//  h1.large {
//   text-transform: uppercase;
//   font-size: 2.4vw;
// }
.inner-banner {
  position: relative;
  min-width: 100%;
  height: 75%;
  // margin: 0 0 3vw 0;
  overflow: hidden;
}
.inner-banner__container {
  height: 100%;
  position: relative;
}
.inner-banner__background-img {
  background-image: url(/assets/images/inner-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
}
.inner-banner__content {
  position: relative;
  top: 25%;
  color: #fff;
}
.process__section__back svg {
  width: 15%;
}
div#process h5 {
  color: #212121;
}
.animate .svg_path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: createpath 1s forwards;
  animation-iteration-count: 1;
}
/* ==================================================
  #Partner in Your Success
================================================== */
section.about__us {
  display: -webkit-box;
}
.counter {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
}
.column {
  padding: 1.5%;
  flex: 1 0 25%;
  color: #0082cb;
}
.column:nth-child(even) {
  color: #2db1ff;
}
.about__us__content {
  padding: 2vw 1vw;
}
.card h4 {
  color: #212121;
  font-weight: 500;
  text-transform: uppercase;
}
.card h1.large {
  font-size: 3vw;
}

.owl-carousel .owl-item img {
  display: initial !important;
  width: unset;
}
.testimonial__pic img {
  // display: initial !important;
  height: 100%;
  width: unset !important;
}

/* ==================================================
  #Delivering best value for our customers
================================================== */

div#value {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 2vw 0 0 0;
}
.value__service-box {
  padding: 1vw 1.5vw 1.2vw 1.5vw;
  transition: all 0.3s ease 0s;
  flex: 1 0 31%;
}
.value__icon.primary img {
  width: 45% !important;
}
.value__text h5 {
  padding: 0 1vw;
}
img.value__img {
  padding: 2vw;
}
.value__icon.primary {
  background: #2db1ff;
  box-shadow: 0 2vw 2vw 0 rgb(32 99 238 / 10%);
}
.value__icon {
  border-radius: 100%;
  width: 6.3vw;
  height: 6.3vw;
  line-height: 6.3vw;
  margin: 0 auto 1vw;
  position: relative;
}
.hover-fx {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  position: relative;
  transition: 300ms;
}
.hover-fx:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  box-sizing: content-box;
  box-shadow: 0 0 0 3px #0082cb;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 300ms;
}
.value__service-box:hover .hover-fx {
  background-color: #0082cb;
}
.value__service-box:hover .hover-fx:after {
  opacity: 1;
  transform: scale(1.15);
}

/* ==================================================
   #process
  ================================================== */
div#process img.back {
  padding: 0.3vw;
}
div#process {
  padding: 2vw 1vw 0 1vw;
  display: -webkit-box;
}
.process__section {
  clear: both;
  display: -webkit-box;
}
.process__content {
  top: 7vw;
}
.process__content h1 {
  text-transform: uppercase;
}
img.back {
  width: 100% !important;
  height: 11vw;
}
.process__section__back:nth-child(2) {
  padding: 0 12vw 0 0;
  margin: 0 auto;
  text-align: right;
}
.process__section__back:nth-child(4) {
  padding: 0 0 0 12vw;
  margin: 0 auto;
}

/* ==================================================
   #quote dark
  ================================================== */
.bg-dark {
  position: relative;
  background: url(/assets/images/shape-dark-bg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 25vw;
  z-index: 6;
  // top: -13vw;
  margin-top: -13vw;
  clear: both;
  display: -webkit-box;
}
section.footer.second {
  top: -8vw;
  padding: 0;
}
.bg-dark .main-title,
.dark-wave .main-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: #fff;
}
section.connect.bg-light.second:after {
  display: none;
}
section.testimonial.top-shape::before {
  display: none;
}
section.testimonial.top-shape {
  padding: 0;
}
/* ==================================================
   #new footer
  ================================================== */

section.bg-dark.new_bg {
  background: url(/assets/images/wave_footer.svg);
  height: 80%;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
section.bg-dark.new_bg .main-title h1.large {
  padding: 6vw 0 0 0;
}
.new_bg .footer ul.menu li a {
  color: #fff;
}
.new_bg ul.footer__social li a {
  color: #ffffff;
}
.new_bg .footer {
  text-align: left;
  padding: 4vw 0 0 0;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  width: 100%;
  clear: both;
  background-color: #fff0;
}
.new_bg section.footer.second h5 {
  padding: 0;
}
.new_bg section.footer.second {
  padding: 5vw 0 0 0;
}
.new_bg.project {
  background-image: url(/assets/images/wave_footer1.svg);
  height: 90% !important;
  background-size: cover !important;
  background-position: top !important;
  background-repeat: no-repeat !important;
}
.footer__bottom.second {
  position: inherit;
  bottom: 17.3vw;
  display: flex;
  float: none;
  margin: 0;
  background-color: #ffffff;
}
section.bg-dark.new_bg.project .quote,
section.bg-dark.new_bg .quote {
  min-width: inherit !important;
}
.new_bg .main-title h5 {
  padding: 0;
}

/* ==================================================
   #services
  ================================================== */

.services__box {
  clear: both;
  display: -webkit-box;
}
section.services {
  display: -webkit-box;
}
.services__content {
  padding: 4vw 1vw 2vw 1vw;
}

/* ==================================================
   #Experts
  ================================================== */
.dark-wave {
  position: relative;
  // background: url(/assets/images/dark_wave.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 37vw;
  z-index: 9;
  clear: both;
  display: -webkit-box;
  background: #1994d9;
}
.dark-wave h1.large {
  line-height: 1.35;
}
.service_btn {
  // display: block;
  line-height: 21px;
}

/* ==================================================
   #technology
  ================================================== */
.technology .owl-carousel .owl-item img {
  display: inherit;
  width: unset;
}
div#technology {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 2vw 0 0 0;
}
.technology__service-box {
  padding: 0.7vw 1.2vw 1vw 1.5vw;
  transition: all 0.3s ease 0s;
  // flex: 1 0 13%;
}
.technology__icon img {
  width: 50%;
}
.technology__icon {
  border-radius: 100%;
  background: #2db1ff;
  box-shadow: 0 2vw 2vw 0 rgb(2 49 88 / 0.1);
  width: 4.3vw;
  height: 4.3vw;
  line-height: 4.3vw;
  margin: 0 auto 0.3vw;
  position: relative;
}
.technology__service-box:nth-child(even) .technology__icon {
  background: #0082cb;
}
.technology__icon.hover-fx:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  box-sizing: content-box;
  box-shadow: 0 0 0 3px #2db1ff;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 300ms;
}
.technology__service-box:nth-child(even) .technology__icon.hover-fx:after {
  box-shadow: 0 0 0 3px #0082cb;
}
.technology__service-box:hover .hover-fx:after {
  opacity: 1;
  transform: scale(1.15);
}

/* ==================================================
  #Portfolio page
  ================================================== */
section.tab.portfolio .tab__tab-box {
  flex: 1 0 50%;
  padding: 2vw 2vw 0vw 2vw;
  background: transparent;
  margin-bottom: 28px;
}
section.tab.portfolio .tab__tab-box:hover {
  box-shadow: 0vw 0vw 1.83vw 0 rgb(0 0 0 / 10%);
  border-radius: 0;
}
section.tab.portfolio .tab__tab-box.last:last-child:hover {
  box-shadow: 0vw 0vw rgb(0 0 0 / 0%);
}
section.tab.portfolio .tab__img {
  padding: 0 0 1vw 0;
}

/* ==================================================
  #Blog page
  ================================================== */
div#blog_inner {
  display: flex;
  padding: 1vw;
  flex-flow: wrap;
}
#blog_inner .blog__box {
  flex: 1 0 43%;
  margin: 2vw;
  min-height: 35vw;
}

/* ==================================================
  #Contact page
  ================================================== */
div#connect__inner ul {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin: 2vw 3vw 0 3vw;
}
div#connect__inner ul .contact__bg-hover {
  margin: 1.2vw;
  min-height: 15vw;
  flex: 1 0 45%;
}
div#connect__inner ul .contact__bg-hover:nth-child(3)::after {
  background-color: #2db1ff;
}
div#connect__inner ul.social {
  padding: 1vw !important;
  margin: 0 auto !important;
}
.connect__form {
  padding: 1.5vw 3vw 5vw 3vw;
  width: 85%;
  margin: 0 auto;
}

/* ==================================================
   #Map
  ================================================== */
.responsive-map {
  padding-bottom: 30%;
  position: relative;
  z-index: 9;
}
.responsive-map iframe {
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
}

/* ==================================================
   #Testimonial page
  ================================================== */

.testimonials {
  .testimonial__block {
    border-radius: 10px;
    padding: 2vw;
    justify-content: flex-start;
  }
  .testimonial__block:before {
    content: url(/assets/images/client-back.svg);
    position: absolute;
    z-index: 11;
    top: 2vw;
    transform: scale(1.25);
  }
  .testimonial__block:after {
    content: url(/assets/images/client-back.svg);
    position: absolute;
    bottom: 2vw;
    right: 2.3vw;
    z-index: 11;
    transform: rotate(180deg) scale(1.25);
  }
  .testimonial__data {
    display: flex;
    justify-content: end;
    .testimonial__img {
      width: 65%;
    }
    img {
      border-radius: 2.705px;
    }
  }
  .client__description {
    padding: 2vw 0 6vw 0;
  }
  .testimonial_description {
    padding: 3vh 0 7vh;
  }
  .testimonial__review {
    text-align: end;
    margin-right: 0.7vw;
    width: 100%;
  }
  .testimonial__designation {
    border-bottom: 1px solid black;
    padding: 10px 0;
  }
  .testimonial__description:before {
    display: none;
  }
  .linkedIn-icon {
    padding-top: 10px;
  }
  .linkedIn-icon i {
    margin-left: 1px;
    color: #fff;
    background: #0a66c2;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 25px;
  }
}
.client__description {
  text-align: center;
}

/* ==================================================
   #Career page
  ================================================== */
div#different {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 2vw 0 0 0;
}
.different__box {
  padding: 1.5vw 0.6vw 1.1vw 0.6vw;
  margin: 0.5vw 0.7vw 1vw 0.7vw;
  transition: all 0.3s ease 0s;
  flex: 1;
}
div#different img {
  width: 60%;
  height: 6vw;
}
.jobs__tab-label h2 {
  color: #212121;
  display: inline-block;
}
/* Accordion styles */
.jobs__tab input {
  opacity: 0;
  display: none;
}
.jobs {
  display: flex;
}
.jobs .jobs__col {
  flex: 1;
}
.jobs .jobs__col:last-child {
  margin-left: 1em;
}
.jobs__tabs {
  border-radius: 8px;
  overflow: hidden;
}
.jobs__tab {
  width: 100%;
  color: #212121;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgb(129 129 129 / 30%);
}
.jobs__tab-label {
  display: block;
  justify-content: space-between;
  padding: 1.5vw 2.5vw;
  font-size: 1.4vw;
  background: #ffffff;
  font-weight: 600;
  margin: 1.2vw 0 0 0;
  cursor: pointer;
}
.jobs__tab-label::after {
  // content: "View More";
  font-size: 1.1vw;
  font-weight: 500;
  cursor: pointer;
  color: #212121;
}
.jobs__tab-content {
  max-height: 0;
  padding: 0 1em;
  background: #fff;
  box-shadow: 0 4px 4px -2px rgba(1, 0, 0, 0.7);
  transition: all 0.35s;
}
// input:checked + .jobs__tab-label {
//   background: #2db1ff;
//   color: #fff;
// }
// input:checked + .jobs__tab-label h2,
// input:checked + .jobs__tab-label h5,
.jobs__tab-label-open h5,
.jobs__tab-label-open h2 {
  color: #fff;
}
input:checked + .jobs__tab-label::after {
  display: none;
}
input:checked ~ .jobs__tab-content {
  max-height: 100%;
  padding: 1vw 2vw 4vw 2vw;
}
a.btn.btn-bordered.accordion {
  float: right;
  position: relative;
  border: 0.1vh solid #212121;
  color: #212121;
  right: 2vw;
}
// input:checked + .jobs__tab-label a.btn.btn-bordered.accordion {
.jobs__tab-label-open a.btn.btn-bordered.accordion {
  border: 0.1vh solid #fff;
  color: #fff;
}
.open_content {
  max-height: 100%;
  padding: 1vw 2vw 4vw 2vw;
}
.jobs__tab-label-open {
  background: #2db1ff;
  color: #fff !important;
}
a.btn.btn-bordered.accordion:hover {
  border: none;
  color: #fff;
}
input:checked + .jobs__tab-label a.btn.btn-bordered.accordion:hover {
  border: none;
}
.jobs__tab-content ul h3 {
  font-weight: 600;
}
.jobs__tab-content ul {
  list-style: inside;
  padding: 1vw;
}
.jobs__tab-content ul li {
  color: #888888;
  line-height: 1.8;
  font-size: 1vw;
}

/* send resume */
.bg-resume {
  position: relative;
  background: url(/assets/images/career__bg.svg);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 25vw;
  top: 0vw;
  margin-bottom: -3vw !important;
  clear: both;
  display: -webkit-box;
}
.bg-resume .main-title {
  position: absolute;
  top: 34%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0%);
  color: #fff;
}
.bg-resume .button-group {
  float: right;
  margin: 2vw 0 0 0;
}
.bg-resume h1.large {
  width: 60%;
  float: left;
}
img.value__img.career {
  width: 70%;
}

/* ==================================================
   #Web Development
  ================================================== */
.web_development .about__us__content h1 {
  text-align: left;
}
h1.large.main-banner__dark-title {
  font-weight: 700;
  // margin: 0 !important;
}
.service__content.mini-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0.99;
  color: #212121 !important;
  margin: 4vw auto 0 auto;
  height: 80%;
}
.service_illustration img.vert-move {
  height: 50vh;
}
section.inner-banner.white__bg {
  height: 90%;
}
.black nav ul li a h5,
.black nav ul li a h5:visited {
  color: #212121;
}
.web_development {
  display: -webkit-box;
}
.web_development_card {
  position: relative;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}
.web_development_card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5vw 1vw;
}
.web_development_media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.web_development_media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 1vw 0 0 0;
}
.web_development_img {
  padding: 1vw 0.7vw 0 0;
  height: 3.51vw;
}
.portfolio__box {
  padding: 0 1vw;
}
.portfolio__img img {
  width: 100%;
}
.portfolio__text {
  padding: 1vw;
}
div#capabilities {
  display: -webkit-box;
  padding: 1vw 3vw;
}
ul.capabilities-list h2 {
  padding: 0vw 1vw 0 0;
}
ul.capabilities-list h5.text-light {
  padding: 0.2vw 0 0 0;
}
section.capabilities {
  float: left;
  display: -webkit-box;
}
.capabilities-list {
  list-style: none;
  text-align: left;
}
.capabilities-list ul {
  margin-left: 0;
}
.capabilities-list ul > li {
  list-style: none;
  margin-bottom: 5px;
}
.capabilities-list ul li h5 {
  display: flex;
  background-color: #fff;
  color: #212121;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  padding: 1vw 4vw 1vw 2.5vw;
  border-radius: 4px;
  position: relative;
  transition: 0.1s;
  transform: perspective(1px) translateZ(0);
}
.capabilities-list ul li h5:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2db1ff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.capabilities-list ul li h5:hover,
.capabilities-list ul li h5:focus,
.capabilities-list ul li h5:active {
  color: white;
}
.capabilities-list ul li h5:hover:before,
.capabilities-list ul li h5:focus:before,
.capabilities-list ul li h5:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.capabilities-list ul li h5:after {
  font-weight: lighter;
  font-family: "FontAwesome";
  -moz-osx-font-smoothing: grayscale;
  content: "\f061";
  color: #2db1ff;
  position: absolute;
  right: 1.5vw;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.capabilities-list ul li h5:hover:after {
  color: #fff;
}

/* ==================================================
   #Mobile Development
  ================================================== */
section.mobile_development.bg-light {
  display: -webkit-box;
}
.web_development_media-body h5 {
  line-height: normal;
  padding: 0.5vw 0 0 0;
}
div#mobile {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 2vw 2vw 0.7vw 2vw;
}
.mobile__mobile-box {
  padding: 1.5vw 1vw 1.1vw 1.2vw;
  margin: 0.5vw 0.7vw 1vw 0.7vw;
  transition: all 0.3s ease 0s;
  flex: 1 0 31%;
}
.mobile__mobile-box:hover {
  box-shadow: 0 1px 20px 0px #efefef;
}
.mobile__mobile-box:hover .mobile__text h2 {
  color: #2db1ff;
}
section.development {
  display: -webkit-box;
}
.development_box {
  padding: 1vw 0.5vw 1.2vw 0.5vw;
  transition: all 0.3s ease 0s;
  flex: 1 0 16%;
}
div#development {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 2vw 0 0 0;
  float: left;
  width: 100%;
}
img.development_img {
  width: 85%;
  margin: 0 auto;
  display: block;
}
.development_icon {
  border-radius: 100%;
  width: 5.3vw;
  height: 5.3vw;
  line-height: 5.3vw;
  margin: 0.5vw auto 1vw auto;
  position: relative;
}
.development_icon.primary img {
  width: 3vw;
}
.development_icon.primary {
  background: #2db1ff;
  box-shadow: 0 2vw 2vw 0 rgb(32 99 238 / 10%);
}
.development_box:hover .hover-fx {
  background-color: #0082cb;
}
.development_box:hover .hover-fx:after {
  opacity: 1;
  transform: scale(1.15);
}
div#development .web_development_card-body {
  padding: 1.3vw;
}

/* ==================================================
   #API Navigation
  ================================================== */
h5.service_description {
  color: #888888;
}
section.approach {
  display: -webkit-box;
  padding: 2vw 0;
}
.approach_single .icon {
  background: url(/assets/images/approach_icon2.svg) no-repeat;
  width: 60px;
  background-size: cover;
  background-position: center;
  display: block;
  margin: 1vw auto;
}
.approach_single {
  padding: 1vw 1.4vw;
  background: #fff;
  box-shadow: 0 0 30px 0 #dddddd82;
  border-radius: 1vw;
}
.approach_single:hover {
  background-color: #0082cb;
  color: #fff;
}
.approach_single:hover .text-light {
  color: #fff;
}
.approach_single:hover img {
  visibility: hidden;
}
.approach_block .width-6 {
  padding: 0 1vw;
}
.approach_block .width-6:nth-child(2) .approach_single,
.approach_block .width-6:nth-child(4) .approach_single {
  margin-top: 2.2vw;
}
.approach_block_content {
  margin: 3vw 1vw 0 0vw;
}
.specialization_img {
  float: left;
  width: 25%;
  padding: 1.1vw 0 0 0;
  position: relative;
  text-align: center;
}
.specialization_img h2 {
  padding: 0 50px 0 0;
}
li.specialization_item {
  overflow: hidden;
  position: relative;
  display: block;
  padding: 1vw;
  background: #fff;
  width: 80%;
  margin: 2vw auto 0 auto;
}
li.specialization_item:hover {
  background: #eceff1;
}
.specialization_text {
  width: 75%;
  padding: 0.5vw;
  display: block;
  vertical-align: middle;
  float: right;
}
li.specialization_item::before {
  content: "";
  height: 14vw;
  background: #eceff1;
  position: absolute;
  transform: translateY(-50%) rotate(45deg);
  width: 24%;
  left: -3.5vw;
  top: 50%;
}
li.specialization_item::after {
  content: "";
  height: 100%;
  background: #eceff1;
  position: absolute;
  width: 0.7vw;
  right: 0;
  top: 0;
}
li.specialization_item:hover::before {
  background: #fff;
}
li.specialization_item:hover::after {
  background: #fff;
}
li.specialization_item:nth-child(even) .specialization_img {
  float: right;
  text-align: right;
  padding: 1.1vw 0 0 0;
}
li.specialization_item:nth-child(even) .specialization_text {
  float: right;
  text-align: right;
}
li.specialization_item:nth-child(even) .specialization_img h2 {
  float: right;
  text-align: right;
  padding: 0;
}
li.specialization_item:nth-child(even):before {
  right: -3.5vw;
  left: auto;
}
li.specialization_item:nth-child(even):after {
  left: 0;
}
li.specialization_item:nth-child(even) .specialization_img img.img-list-src {
  margin: 0 2vw 0 0;
}
img.img-list-src {
  width: 40%;
  margin: 0 0 0 2vw;
}

/* ==================================================
   #UI/UX DESIGN
  ================================================== */
section.advantages {
  float: left;
}
li.advantages_item {
  display: inline-block;
  padding: 4vw 1vw 0vw 1vw;
  height: 100%;
  vertical-align: middle;
}
li.advantages_item:nth-child(even) {
  width: 85%;
  float: right;
}
.advantages_img {
  width: 9.9vw;
  background: #fff;
  float: right;
  height: 100%;
  position: relative;
  text-align: center;
  margin: 1.5vw 0vw 0 2vw;
  padding: 1vw 0;
  box-shadow: 10px 30px 50px rgba(6, 31, 60, 0.09);
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transition:
    box-shadow 0.3s ease-out,
    transform 0.3s;
}
li.advantages_item:hover .advantages_img {
  box-shadow: 2px 10px 30px rgba(6, 31, 60, 0.2);
}
li.advantages_item:hover .advantages_text h1:before,
li.advantages_item:hover .advantages_img:before {
  border-color: transparent transparent #0082cb transparent;
}
.advantages_img:before {
  content: "";
  position: absolute;
  display: block;
  top: -15%;
  z-index: -1;
  left: 0.35vw;
  transform: translateX(-50%) rotate(-45deg);
  border-style: solid;
  border-width: 0 3.66vw 3.66vw 3.66vw;
  border-color: transparent transparent #2db1ff transparent;
}
.advantages_text {
  width: 77%;
  float: left;
}
.advantages_text h1 {
  position: relative;
  float: none;
  width: 100%;
  flex-direction: column-reverse;
  display: flex;
}
.advantages_text h1:before {
  content: "";
  position: absolute;
  display: block;
  top: -1.17vw;
  z-index: -1;
  transform: translateX(-50%) rotate(-45deg);
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #2db1ff transparent;
}
li.advantages_item:nth-child(2) .advantages_img:before {
  bottom: -15%;
  top: auto;
  transform: translateX(-50%) rotate(225deg);
}
li.advantages_item:nth-child(3) .advantages_img:before {
  bottom: -15%;
  top: auto;
  right: -34%;
  left: auto;
  transform: translateY(0%) rotate(135deg);
}
li.advantages_item:nth-child(4) .advantages_img:before {
  top: -15%;
  left: 59%;
  transform: translateY(-3%) rotate(45deg);
}
div#box h5 {
  margin: 1.5vw 0 0 0;
}
.advantages_img h5 {
  color: #2db1ff;
  font-weight: 500;
  font-size: 1vw;
}
.advantages_text h1::after {
  content: "";
  position: absolute;
  height: 0.15vw;
  width: 100%;
  margin: -0.5vw 0;
  float: none;
  background: linear-gradient(
    60deg,
    #2db1ff,
    #0082cb,
    #1ea0eb,
    #2b2e30,
    #1192dc,
    #1098ad,
    #2db1ff,
    #0082cb
  );
  z-index: -1;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.advantages_img img {
  width: 4vw;
  height: 4vw;
  margin: 1vw 0 0.3vw 0;
}
section.lean_ux_process {
  display: -webkit-box;
}
div#ux_process {
  margin: 1vw 0 0 0px;
}
.text h2 span {
  padding: 0 1vw 0 0;
}
img.vert-move.ux_process {
  padding: 0;
  margin: 3.5vw 0 0 0;
}
.lean_ux_process .ux-process {
  text-align: center;
  width: 660px;
  max-width: 100%;
  margin: 0 auto;
}
.lean_ux_process .ux-process .step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0px;
  text-align: left;
  position: relative;
}
.lean_ux_process .ux-process .step {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1vw;
}
.lean_ux_process .ux-process .step .scroll-point {
  background: #2db1ff;
  transition: all 0.3s ease-in-out;
}
.lean_ux_process .ux-process .step:hover .scroll-point:after {
  opacity: 1 !important;
  transform: scale(1.25);
}
.lean_ux_process .ux-process .step .scroll-point:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  box-sizing: content-box;
  box-shadow: 0 0 0 2px #2db1ff;
  z-index: 100;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 300ms;
}
.lean_ux_process .ux-process .step .scroll-point {
  width: 15px;
  height: 15px;
  background-color: #2db1ff;
  border-radius: 50%;
  position: absolute;
  left: -60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
.picto {
  width: 25%;
}
.picto img {
  width: 80%;
}
.ux-process .text {
  width: 100%;
}
.lean_ux_process .ux-process .step:hover h2 {
  color: #0082cb;
}
.lean_ux_process .ux-process .step:before {
  height: calc(200% - 15px);
  background-color: #2db1ff;
}
.lean_ux_process .ux-process .step:after,
.lean_ux_process .ux-process .step:before {
  content: "";
  height: calc(130% - 9px);
  width: 3px;
  background-color: #2db1ff;
  display: block;
  position: absolute;
  bottom: 43%;
  left: -54px;
  transition: height 0.4s ease-in-out;
}
.step.ux-process-step.first.reached:after,
.step.ux-process-step.first.reached:before,
.step.ux-process-step.first.last:after {
  background-color: transparent;
  display: none;
}

/* ==================================================
   #Testing
  ================================================== */
section.solutions {
  display: -webkit-box;
}
.solutions_box {
  background-color: #fff;
  border-radius: 6px;
  padding: 2vw 2vw;
  margin: 2vw 0;
  min-height: 32vw;
  box-shadow: 10px 30px 50px rgba(6, 31, 60, 0.09);
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transition:
    box-shadow 0.3s ease-out,
    transform 0.3s;
}
.solutions_box:hover {
  -webkit-box-shadow: 0 10px 30px rgba(6, 31, 60, 0.2);
  -moz-box-shadow: 0 10px 30px rgba(6, 31, 60, 0.2);
  box-shadow: 2px 10px 30px rgba(6, 31, 60, 0.2);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
}
.solutions_box img {
  height: 6vw;
}
.solutions_box h2 {
  margin: 18px 0 0 0;
}
.solutions_box h5 {
  margin: 17px 0 0 0;
}
section.test {
  display: -webkit-box;
}
ul.test_list {
  padding: 2vw 0 0 1.7vw;
}
.checkmark:before {
  content: "\2713";
}
.test_list li {
  list-style: none;
  font-weight: 500;
  font-size: 1.1vw;
  padding: 0.5vw 0 0 0;
  transition:
    margin-left 0.3s linear,
    font-weight 0.2s linear,
    color 0.3s linear;
}
.test_list li:hover {
  margin-left: 8px;
}
.checkmark:before {
  content: "\2713";
  font-weight: 700;
  padding: 0 1vw 0 0;
  color: #2db1ff;
}

/* ==================================================
   #Upgradation
  ================================================== */
.solutions_item {
  display: -webkit-box;
  padding: 2vw 0;
}
.benefits_block {
  transition:
    margin-left 0.3s linear,
    font-weight 0.2s linear,
    color 0.3s linear;
  display: inline-block;
  padding: 0.2vw 0.8vw;
}
.benefits_block:hover {
  margin-left: 5px;
}
.benefits_block:hover h2 {
  color: #2eb1ff;
}
img.benefits_icon {
  float: right;
  margin: 0.7vw 0 0 0px;
}
section.upgrade {
  display: -webkit-box;
}
.upgrade_block {
  margin: 3vw 0 0 0;
}
.upgrade_block h1 {
  color: #2db1ff;
}
.upgrade_bg {
  background: #2db1ff;
  height: 3vw;
}
.slider-nav-thumbnails.slick-initialized.slick-slider {
  top: -2.5vw;
}
.slider.slick-initialized.slick-slider button {
  height: 2.7vw;
}
button.slick-prev.slick-arrow {
  position: absolute;
  top: 20vw;
  left: 5%;
  color: transparent;
  clear: both;
  border: none;
  background: #fff;
  outline: none;
  border-radius: 50%;
  width: 2.7vw;
  box-shadow: 0 0vw 0.5vw 0vw rgb(0 0 0 / 0.1);
}
button.slick-next.slick-arrow {
  position: absolute;
  top: 20vw;
  left: 25%;
  color: transparent;
  outline: none;
  clear: both;
  border: none;
  background: #fff;
  border-radius: 50%;
  width: 2.7vw;
  box-shadow: 0 0vw 0.5vw 0vw rgb(0 0 0 / 0.1);
}
button.slick-prev.slick-arrow::before {
  content: "\f104";
  font-family: FontAwesome;
  left: 0;
  font-size: 2vw;
  position: relative;
  top: 0;
  color: #2db1fe;
  font-weight: 700;
}
button.slick-next.slick-arrow::before {
  content: "\f105";
  font-family: FontAwesome;
  left: 7%;
  font-size: 2vw;
  position: relative;
  top: 0;
  color: #2db1fe;
  font-weight: 700;
}
button.slick-prev.slick-arrow:hover {
  border: none;
  background: #2eb1ff;
  outline: none;
}
button.slick-next.slick-arrow:hover {
  border: none;
  background: #2eb1ff;
  outline: none;
}
button.slick-next.slick-arrow:hover::before,
button.slick-prev.slick-arrow:hover::before {
  color: #fff;
}
.upgrade_slider .slick-slide img {
  display: block;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translate(0, -50%);
}
.upgrade__box.slick-slide.slick-current .upgrade_icon {
  background: #0082cb;
}
.upgrade__box.slick-slide.slick-current .hover-fx:after {
  opacity: 1;
  transform: scale(1.15);
}
.upgrade__box {
  padding: 3vw 2vw;
  margin: 1vw;
  box-shadow: 0 0vw 0.5vw 0vw rgb(0 0 0 / 0.1);
  border-radius: 0.5vw;
  min-height: 38vh;
  text-align: center;
  background: #fff;
}
.upgrade_icon {
  border-radius: 100%;
  width: 6.3vw;
  background: #2db1ff;
  box-shadow: 0 2vw 2vw 0 rgb(32 99 238 / 10%);
  height: 6.3vw;
  line-height: 6.3vw;
  margin: 0 auto 1.5vw;
  position: relative;
}
.upgrade_icon img {
  height: 3vw;
}
.upgrade_slider {
  padding: 0 2vw;
}
#slider {
  margin: 0 auto;
}
#slider img {
  width: 100%;
}
#upgrade ul.slick-dots {
  display: none !important;
}
#upgrade .slide-arrow {
  position: absolute;
  top: 50%;
  margin-top: -15px;
}
#upgrade .prev-arrow {
  left: -40px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #fff;
  color: #2db1ff;
  border: none;
  box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 0.1);
  font-size: 3vw;
  font-weight: 600;
}
.prev-arrow:before {
  content: "\f104";
  font-family: "FontAwesome";
}
.next-arrow:before {
  content: "\f105";
  font-family: "FontAwesome";
}
#upgrade .next-arrow {
  right: -40px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #fff;
  color: #2db1ff;
  border: none;
  box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 0.1);
  font-size: 3vw;
  font-weight: 600;
}
#upgrade .next-arrow:focus,
#upgrade .prev-arrow:focus {
  border: none;
  text-decoration: none;
  outline: none;
}
#upgrade .next-arrow:hover,
#upgrade .prev-arrow:hover {
  background: #2db1ff;
  color: #fff;
}
.slick-slide {
  height: auto !important;
}
.upgrade_slider {
  padding: 0 2vw;
}
#upgrade .owl-controls.clickable {
  left: -45% !important;
  top: 50%;
  position: absolute;
}

/* ==================================================
   #Blog Details
  ================================================== */
section.blog_detail img {
  // width: 50%;
}
.blog_info ul {
  list-style: none;
  float: left;
  padding: 1.3vw 0;
}
.blog_info ul li {
  display: inline-block !important;
  padding: 0 1vw;
  font-size: 1vw;
  position: relative;
  font-weight: 600;
}
.blog_info ul li a {
  font-weight: 600;
}
.blog_info ul li::after {
  position: absolute;
  content: "";
  height: 30%;
  width: 2px;
  background-color: #888888;
  bottom: 20%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.blog_info ul li:last-child::after {
  display: none;
}
.blog_info {
  border-bottom: 1px solid #f9f9f9;
  display: flow-root;
}
.blog_content {
  width: 85%;
  margin: 0 auto;
}
.blog-details h1 {
  font-weight: 600;
  padding: 1vw 0 0.1vw 0;
}
.blockquote {
  background-color: #f9f9f9;
  position: relative;
  margin: 2vw;
  padding: 1.5vw;
}
.blockquote::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 2px;
  background: linear-gradient(45deg, #2db1ff 15%, #0082cb 65%);
  top: 0;
  left: 0;
}
.blockquote h5 {
  font-weight: 500;
}
.blog-details {
  clear: both;
}
.effect {
  padding: 1vw;
  float: right;
}
.effect .buttons {
  display: flex;
  justify-content: center;
}
.effect a:last-child {
  margin-right: 0px;
}
.effect a {
  text-decoration: none !important;
  color: #fff;
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 1vw;
  font-size: 1.5vw;
  background-color: #2db1ff;
  overflow: hidden;
  position: relative;
}
.effect a i {
  position: relative;
  z-index: 3;
}
/* aeneas effect */
.effect.aeneas a {
  transition:
    transform 0.4s linear 0s,
    border-top-left-radius 0.1s linear 0s,
    border-top-right-radius 0.1s linear 0.1s,
    border-bottom-right-radius 0.1s linear 0.2s,
    border-bottom-left-radius 0.1s linear 0.3s;
}
.effect.aeneas a i {
  transition: transform 0.4s linear 0s;
}
.effect.aeneas a:hover {
  transform: rotate(360deg);
  border-radius: 50%;
}
.effect.aeneas a:hover i {
  transform: rotate(-360deg);
}
.blog_detail a.left-btn {
  margin-top: 3vw;
  line-height: normal;
  justify-content: start;
  align-items: center;
}
.blog_detail a.right-btn {
  margin-top: 3vw;
  line-height: normal;
  justify-content: end;
  align-items: center;
  text-align: end;
}
.blog_detail .next_prev_blog a:hover {
  color: #2db1fe;
}
.blog_detail .next_prev_blog a span:hover {
  text-decoration: underline;
}
.blog_detail .next_prev_blog a i:hover {
  text-decoration: none;
}
.next_prev_blog {
  display: flex;
  flex-wrap: wrap;
}
.next_prev_blog a {
  display: inline-flex;
  flex: 0 50%;
  font-size: 20px;
}
/*==================================================
   #404
  ================================================== */
.error {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: url(/assets/images/404_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  max-height: 100vh;
  z-index: 111;
  position: absolute;
  top: 0;
  left: 0;
}
.error__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  letter-spacing: 0.2vw;
  line-height: normal;
}
.error__message {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.error__message h1 {
  padding: 1vw 0;
}
.error__message img {
  width: 50%;
}
h1.big_number {
  font-size: 5vw;
}
.error__message .button-group {
  margin: 2.99vh 0 0 0;
}

/* ==================================================
   #Project Page
  ================================================== */
section.bitwiser {
  display: -webkit-box;
}
.inner-banner__background-img.project_bg1:before {
  background: linear-gradient(90.44deg, #385a9a 0.31%, #385a9a 97.75%);
  content: "";
}
.project_bg1 .inner-banner__content {
  top: 28%;
}
ul.bitwiser_block {
  list-style: none;
}
.bitwiser_block li {
  padding: 0.5vw 0;
  left: 3vw;
  font-size: 0.85vw;
  font-weight: 400;
  position: relative;
  transition:
    margin-left 0.3s linear,
    font-weight 0.2s linear,
    color 0.3s linear;
}
.bitwiser_block li:hover {
  margin-left: 8px;
}
// right arrow fill svg
.bitwiser_block li:before {
  // content: url(/assets/images/project_icon1.svg);
  content: var(--featureBackground);
  position: absolute;
  left: -2.5vw;
  top: 0.8vw;
  width: 2%;
}
.bitwiser_block.shape li {
  padding: 0;
  left: 5vw;
  font-size: 1vw;
  font-weight: 400;
  width: 10vw;
}
// right arrow border svg
.bitwiser_block.shape li:before {
  // content: url(/assets/images/bitwiser_block_shape.svg);
  content: var(--featureBorder);
  position: absolute;
  left: -2.5vw;
  top: 0.5vw;
  width: 14%;
}
ul.bitwiser_block.shape {
  top: 1vw;
  position: relative;
}
#screenshots .blog__box,
#screenshots1 .blog__box {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0 0.7vw 0;
  border-radius: 1vw;
  margin: 0 auto;
  position: relative;
  float: none;
  width: 70%;
  min-height: auto;
}
.technology #tab .tabs {
  background: #eceff1;
}
.technology .panels {
  background: transparent;
}
.technology .owl-next {
  position: absolute;
  right: 0;
  top: 40%;
}
.screenshot .fa-stack {
  background: $primary;
  border-radius: 50%;
  width: 50px;
}
.screenshot .fa-stack:hover {
  background: #fff !important;
  color: $primary;
}
.blog .fa-stack {
  background: #2db1ff;
  border-radius: 50%;
  width: 50px;
}
.blog .fa-stack:hover {
  background: #fff !important;
  color: #2db1ff !important;
}
.technology .owl-prev {
  position: absolute;
  left: 0;
  top: 40%;
}
.technology .owl-buttons div {
  background: #385a9a !important;
}
.technology .owl-theme .owl-controls.clickable .owl-buttons div:hover {
  color: #385a9a !important;
  background: #fff !important;
}
.tabs_wrapper {
  width: 85%;
  text-align: center;
  margin: 0 auto;
  display: -webkit-box;
  background: transparent;
  flex-flow: wrap;
}
.tabs_wrapper ul.tabs {
  display: block;
  vertical-align: top;
  position: relative;
  z-index: 10;
  padding: 0;
  text-align: left;
  width: 35%;
  list-style: none;
  transition: all 0.3s ease;
}
.tabs_wrapper ul.tabs li {
  margin: 0;
  cursor: pointer;
  padding: 1vw 2vw;
  margin: 0.7vw 0;
  border-radius: 0.5vw;
  line-height: auto;
  color: #212121;
  text-align: left;
  font-weight: 600;
  background: #fff;
  transition: all 0.3s ease;
}
.map_image {
  max-width: 95%;
  padding: 0 0 5vh 0;
}
.map_title {
  margin: 5vh auto 3vh auto;
}

.component-placeholder {
  text-align: center;
  font-size: 1.2em;
  color: gray;
  padding: 10px;
}

// technology two right arrow
.tabs_wrapper ul.tabs li::before {
  // content: url(/assets/images/bitwiser_technology_icon.svg);
  content: var(--background);
  position: relative;
  left: -1vw;
  top: 0.4vw;
}
.tabs_wrapper ul.tabs li.active::before,
.tabs_wrapper ul.tabs li:hover::before {
  content: url(/assets/images/bitwiser_technology_icon_white.svg);
}
// change on hover color
.tabs_wrapper ul.tabs li:hover {
  background: $primary;
  color: #fff;
  transition: all 0.3s ease;
}
.tabs_wrapper ul.tabs li.active {
  background: $primary;
  color: #fff;
  transition: all 0.3s ease;
}
.tabs_wrapper .tab_bg {
  background-image: url(/assets/images/project_shape.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 50%;
  position: relative;
  left: 15%;
  display: block;
}
.tabs_wrapper .tab_container {
  position: relative;
  left: 0;
  padding: 4vw 2vw;
  text-align: left;
}
.tabs_wrapper .tab_content {
  padding: 2vw;
}
.tabs_wrapper .tabs_wrapper ul.tabs li::before {
  content: "\f101";
  padding: 1vw;
  font-family: "FontAwesome";
}
.technology .blog__img img {
  width: 100%;
  height: 31vw;
}
.testim .wrap {
  position: relative;
}
.testim .arrow {
  display: none;
  position: absolute;
  color: #eee;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 22222222;
}
.testim .arrow:before {
  cursor: pointer;
}
.testim .arrow:hover {
  color: #2db1ff;
}
.testim .arrow.left {
  left: 10px;
}
.testim .arrow.right {
  right: 10px;
}
.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  display: none;
  z-index: 3333;
  height: 12px;
}
.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}
.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #212121;
  border-color: #212121;
}
.testim .cont {
  position: relative;
  overflow: hidden;
}
.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 3vw;
  opacity: 0;
}
.testim .cont > div.inactive {
  opacity: 1;
}
.testim .cont > div.active {
  position: relative;
  opacity: 1;
}
.testim .cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}
.testim .cont div h2 {
  color: #212121;
  margin: 15px 0;
}
.testim .cont div p {
  color: #888888;
  width: 80%;
  margin: auto;
  line-height: normal;
}
.bg-dark.project_quote {
  position: relative;
  background: url(/assets/images/project_quote_bg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 26vw;
  z-index: 9;
  top: -13vw;
  clear: both;
  display: -webkit-box;
}
.bg-dark.project_quote .main-title {
  top: 55%;
}
section.bitwiser .main-title,
section.process .main-title,
section.value.bg-light .main-title,
section.different .main-title,
section.value .main-title,
section.capabilities .main-title,
section.advantages .main-title {
  width: 100%;
  line-height: normal;
}
section.job.bg-light {
  display: -webkit-box;
}
a.btn.btn-bordered.bg_project:hover {
  background: linear-gradient(-45deg, #23527c 0%, #385a9a 100%);
}
.slick-slider {
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-slide:focus {
  outline: none;
}
.slick-slide.slick-current.slick-active.slick-center img {
  box-shadow: 0 0.2vw 1vw 0 rgba(0, 0, 0, 0.1);
  border-radius: 1vw;
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.tablet-scale .slick-slide img {
  height: 31vw;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-big-wrap img {
  width: 100%;
}
.slick-center .slick-slide {
  text-align: center;
  border: 1px solid #eee;
  padding: 4.5vw;
  border: none;
}
.mobile-scale .slick-slide {
  padding: 4.5vw !important;
}
.screenshot .slick-center .slick-slide {
  padding: 0.5vh 2vw;
}
.screenshot .slick-center.tablet-scale {
  margin-bottom: 5vw;
}
.screenshot .slick-center-wrap {
  padding: 1vw 0 0 0;
}
.slick-center img {
  text-align: center;
  margin: 0 auto;
}
.slick-center .slick-list,
.slick-center .slick-track {
  overflow: hidden;
}
.slick-center .slick-current {
  width: 0 30px;
  top: 50%;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
.mobile-scale .slick-current {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
.mobile-scale .slick-slide {
  padding: 4.1 !important;
}
section.bg-dark.new_bg1 {
  background: url(/assets/images/wave_footer1.svg);
  height: 90%;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.inner-banner__background-img.project_bg2 {
  background-image: url(/assets/images/inner-bg1.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: bottom;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
}
.new_bg1 section.footer.second {
  padding: 5vw 0 0 0;
}
.slick-center-wrap {
  position: relative;
  padding: 2vw 0 0 0;
}
a.pic-prev.slick-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 2vw;
  position: absolute;
  color: var(--primary-color);
}
a.pic-next.slick-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2vw;
  position: absolute;
  color: var(--primary-color);
}

.ss_arrow {
  font-size: 42px !important;
  // color: #385a9a !important;
  font-weight: bold !important;
  background: #fff;
  color: var(--primary-color) !important;
  box-shadow: 0 0.2vw 2vw 0 rgb(0 0 0 / 10%);
  border-radius: 50%;
  transition: all 0.4s linear;
}

.ss_arrow:hover {
  color: #fff !important;
  background: var(--primary-color) !important;
}
.ss_left {
  padding: 0px 15px 3px 13px;
}
.ss_right {
  padding: 0px 12px 3px 16px;
}
.ss_arrow:before {
  display: flex;
  align-items: center;
  justify-content: center;
}
section.header.black.active .img {
  display: none;
}
header {
  overflow: hidden;
  z-index: 9;
}
.menu-toggle {
  position: fixed;
  top: 1rem;
  right: 1.5rem;
  color: #212121;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1000;
  display: none;
}
nav ul {
  display: block;
}
nav ul li {
  list-style: none;
  padding: 0 1.2vw;
}
nav ul li a {
  padding: 0;
  margin: 0;
  position: relative;
  letter-spacing: 2px;
}
nav ul li a:last-child {
  margin-right: 0;
}
i.fas.fa-bars,
i.fas.fa-times {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 2rem;
}
.overlay {
  background-color: rgb(255 255 255 / 98%);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transition: opacity 650ms;
  transform: scale(0);
  opacity: 0;
  display: none;
}

@keyframes slideIn {
  from {
  }
  to {
    transform: translateX(0);
  }
}

a#read {
  display: none;
}
.firstpage {
  background-color: #0082cb;
}
.owl-carousel {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.inner-banner__content.text-center {
  width: 60%;
}

/* Menu */

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 20px;
  margin: 5px;
  padding: 4px 7px;
  background: transparent !important;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

/* pagination */
span.ng-star-inserted:focus-visible,
span.ng-star-inserted:focus {
  outline: none !important;
}

/*
  A copy of our modal styles you can use.. its implementers choice what styles they want based on their lib of choice. If you make bootstrap modals, use their styles. Simples.
*/
.modal {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 0 16px;
  position: fixed;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 10; /* Adjust according to your needs */
}

.modal.fade-anim {
  transition: opacity 400ms ease-in-out;
  will-change: opacity;
  opacity: 0;
}

.modal.fade-anim.in {
  opacity: 1;
}

.modal-open {
  overflow: hidden;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 4px;
  max-width: 580px;
  position: relative;
  transition: opacity 400ms ease-in-out;
  width: 100%;
  will-change: opacity;
  display: inline-block;
  text-align: left;
}

.modal-content-size-m {
  max-width: 992px;
}

.modal-content-size-l {
  max-width: 1200px;
}

.modal-footer,
.modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding: 0 16px;
}

.modal-header {
  border-bottom: 1px solid #cecece;
}

.modal-body {
  padding: 16px;
}

.modal-footer {
  border-top: 1px solid #cecece;
  padding: 10px 0;
}

.privacy-policy p {
  line-height: 1.5;
}
.terms_of_use ul,
.privacy-policy ul {
  margin-left: 35px;
  line-height: 1.7;
}

.inner-banner-content h1 {
  font-weight: 700;
}
.inner-banner-content .button-group a {
  border: none !important;
}
.portfolio_detail_banner {
  height: 90% !important;
  overflow: visible !important;
  margin: 0 0 100px 0 !important;
}
.portfolio_detail_banner .inner-banner__container {
  overflow: visible !important;
}
.portfolio_detail_banner .inner-banner__background-img {
  overflow: visible !important;
  margin-top: -20px;
}
.portfolio_detail_banner_img {
  margin: 0 auto;
  text-align: center;
  width: 65%;
  position: relative;
  margin-top: 50px;
  top: 50%;
  transform: translateY(-50%);
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes floating {
  0% {
    padding-top: 0px;
  }
  50% {
    padding-top: 15px;
  }
  100% {
    padding-top: -0px;
  }
}

.client_serve_large {
  display: none !important;
}

// footer cookie
.footer-cookie {
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
}
.challenge_description p {
  padding: 0.5vw 0;
  font-size: 1.2vw;
  font-weight: 400;
  position: relative;
  transition:
    margin-left 0.3s linear,
    font-weight 0.2s linear,
    color 0.3s linear;
}
.abt_big_img img {
  max-width: 80%;
  padding: 20px 0;
}
.about__us__content h1.large {
  line-height: normal;
}
.blue_btn {
  color: #0082cb !important;
  border: 1px solid #0082cb !important;
}
.blue_btn:hover {
  color: #fff !important;
  border: none !important;
}
.contact_btn {
  padding: 1.6vh 3vw;
}

.challenge_description h5 {
  color: #888888;
}

// to hide recapcha
.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha-msg {
  text-align: left;
  font-size: 0.85vw;
  line-height: normal;
  font-weight: 400;
  color: #888888;
  margin: 0 0 0.8vw;

  a {
    display: inline;
    font-size: 0.85vw;
    line-height: 1.6vw;
    font-weight: 400;
  }
}
.recaptcha-msg a.recaptcha-link.hover-underline-animation::after {
  bottom: -0.1vw !important;
}

.terms_of_use h3 {
  font-size: 1.5vw;
  line-height: normal;
}
.privacy-policy h3 {
  font-size: 1.5vw;
  line-height: normal;
}
// recaptcha  Responsive

@media (max-width: 500px) {
  .sb-circles-dark .sb-wrapper .sb-icon,
  .sb-circles-light .sb-wrapper .sb-icon {
    width: 2.5em !important;
    min-height: 2.5em !important;
  }
}

// Home popup

.celebration {
  position: relative;
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(13, 80, 122, 0.7);
}
.confetti-piece {
  position: absolute;
  width: 10px;
  height: 30px;
  background: #ffd300;
  top: 0;
  opacity: 0;
}
.confetti-piece:nth-child(1) {
  left: 7%;
  -webkit-transform: rotate(-40deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 182ms;
  -webkit-animation-duration: 1116ms;
}
.confetti-piece:nth-child(2) {
  left: 14%;
  -webkit-transform: rotate(4deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 161ms;
  -webkit-animation-duration: 1076ms;
}
.confetti-piece:nth-child(3) {
  left: 21%;
  -webkit-transform: rotate(-51deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 481ms;
  -webkit-animation-duration: 1103ms;
}
.confetti-piece:nth-child(4) {
  left: 28%;
  -webkit-transform: rotate(61deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 334ms;
  -webkit-animation-duration: 708ms;
}
.confetti-piece:nth-child(5) {
  left: 35%;
  -webkit-transform: rotate(-52deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 302ms;
  -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(6) {
  left: 42%;
  -webkit-transform: rotate(38deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 180ms;
  -webkit-animation-duration: 1168ms;
}
.confetti-piece:nth-child(7) {
  left: 49%;
  -webkit-transform: rotate(11deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 395ms;
  -webkit-animation-duration: 1200ms;
}
.confetti-piece:nth-child(8) {
  left: 56%;
  -webkit-transform: rotate(49deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 14ms;
  -webkit-animation-duration: 887ms;
}
.confetti-piece:nth-child(9) {
  left: 63%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 149ms;
  -webkit-animation-duration: 805ms;
}
.confetti-piece:nth-child(10) {
  left: 70%;
  -webkit-transform: rotate(10deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 351ms;
  -webkit-animation-duration: 1059ms;
}
.confetti-piece:nth-child(11) {
  left: 77%;
  -webkit-transform: rotate(4deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 307ms;
  -webkit-animation-duration: 1132ms;
}
.confetti-piece:nth-child(12) {
  left: 84%;
  -webkit-transform: rotate(42deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 464ms;
  -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(13) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}

.confetti-piece:nth-child(14) {
  left: 7%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}

.confetti-piece:nth-child(15) {
  left: 21%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 464ms;
  -webkit-animation-duration: 776ms;
}

.confetti-piece:nth-child(16) {
  left: 35%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 307ms;
  -webkit-animation-duration: 1132ms;
}

.confetti-piece:nth-child(17) {
  left: 49%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 351ms;
  -webkit-animation-duration: 1059ms;
}

.confetti-piece:nth-child(18) {
  left: 63%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 149ms;
  -webkit-animation-duration: 805ms;
}

.confetti-piece:nth-child(19) {
  left: 77%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 14ms;
  -webkit-animation-duration: 887ms;
}

.confetti-piece:nth-child(20) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 395ms;
  -webkit-animation-duration: 1200ms;
}

.confetti-piece:nth-child(21) {
  left: 14%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 180ms;
  -webkit-animation-duration: 1168ms;
}

.confetti-piece:nth-child(22) {
  left: 28%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 302ms;
  -webkit-animation-duration: 776ms;
}

.confetti-piece:nth-child(23) {
  left: 42%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 334ms;
  -webkit-animation-duration: 708ms;
}

.confetti-piece:nth-child(24) {
  left: 56%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 481ms;
  -webkit-animation-duration: 1103ms;
}

.confetti-piece:nth-child(25) {
  left: 70%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 161ms;
  -webkit-animation-duration: 1076ms;
}

.confetti-piece:nth-child(26) {
  left: 84%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 182ms;
  -webkit-animation-duration: 1116ms;
}

.confetti-piece:nth-child(27) {
  left: 98%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(odd) {
  background: #7431e8;
}
.confetti-piece:nth-child(even) {
  z-index: 1;
}
.confetti-piece:nth-child(4n) {
  width: 5px;
  height: 12px;
  -webkit-animation-duration: 2000ms;
}
.confetti-piece:nth-child(3n) {
  width: 3px;
  height: 10px;
  -webkit-animation-duration: 2500ms;
  -webkit-animation-delay: 1000ms;
}
.confetti-piece:nth-child(4n-7) {
  background: red;
}
@keyframes makeItRain {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(100vh);
  }
}

.img-container {
  width: 35%;
}
@media (max-width: 1200px) and (min-width: 1056px) {
  .img-container {
    width: 40%;
  }
}
@media (max-width: 1600px) and (min-width: 1064px) {
  .specialization_img h2 {
    padding-top: 20px !important;
  }
  .web_development_space {
    padding-top: 25px !important;
  }
}
@media (max-width: 720px) {
  .img-container {
    width: 50%;
  }
  .confetti-piece {
    width: 5px;
    height: 20px;
  }
  .blog_detail .blog-description h3 {
    font-size: 4.5vw;
  }
}
@media (max-width: 1024px) {
  #return-to-top {
    bottom: 17vw !important;
  }
}
@media (max-width: 540px) {
  .img-container {
    width: 60%;
  }
  .upgrade__box {
    min-height: 28vh;
  }
  .testimonials {
    .main-banner__content-illustration img {
      height: 40vh !important;
    }
    .counter {
      flex-wrap: nowrap;

      .column {
        padding: 0%;
        h1 {
          font-size: 6vw;
        }
        h4 {
          font-size: 3.2vw;
        }
      }
    }
    h3 {
      font-size: 3.7vw;
    }
    h5 {
      margin-top: 0;
      font-size: 3.4vw;
    }
    app-read-more > .testimonial-text {
      max-height: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .testimonial__block {
      padding: 5vw;
      p {
        line-height: 19px !important;
        font-size: 13px;
      }
      .testimonial_description {
        padding: 2vh 0 5vh;
      }
    }

    .testimonial__review {
      margin-right: 2vw;
      width: 53%;
    }

    .testimonial__block:before {
      top: 4vw;
      transform: scale(1);
    }

    .testimonial__block:after {
      right: 5vw;
      transform: rotate(180deg) scale(1);
    }

    .client__description {
      padding: 4vw 0 10vw;
    }
    .dark-wave {
      height: 75vw;
      a {
        width: 55%;
        margin: 0 auto;
      }
    }
    .linkedIn-icon {
      padding-top: 9px;
    }
    .linkedIn-icon i {
      font-size: 18px;
    }
  }
}
//blog-list
.blog_time {
  margin: 0;
  line-height: 1px;
}
@media (max-width: 75em) {
  .blog_time {
    font-size: 14px !important;
    padding: 1.5vw 4px;
  }
  app-read-more > .testimonial-text {
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// sponser Footer
.sponsor-footer {
  float: left;
  width: 100%;
  .main-container div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0;
    a {
      padding: 0 20px;
      img {
        max-height: 6rem;
        max-width: 6rem;
      }
    }
    @media (max-width: 540px) {
      a img {
        max-height: 3.5rem;
        max-width: 3rem;
      }
    }
  }
}
.footer-nospace .main-container div {
  border-bottom: 1px solid #d9d9d9;
}
.sponsor-spacing {
  padding-top: 1vw;
  margin: 4.4vw 0 0 0;
  .main-container div {
    border-top: 1px solid #d9d9d9;
  }
}
@media (max-width: 1200px) and (min-width: 1024px) {
  .solutions_box {
    min-height: 47vw !important;
  }
}
